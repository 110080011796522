import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../Images/APEX Global PNG.png';
import Swal from 'sweetalert2';
import Loading_Scren from './LoadingScreen';
import AccountActions from '../../Actions/account.action';
import Credit from '../../Images/Credit Card.jpg';
import Insurance from '../../Images/Insurance.jpg';
import Car from '../../Images/Car Loan.jpg';
import HouseLoan from '../../Images/Home Loan.jpg'
import Carousel from '../../Blocks/CarsoualPromotion';
import axios from 'axios'
import * as ApiUrls from '../../Constants/ApiUrls';
import { context, propagation, trace, SpanStatusCode } from '@opentelemetry/api';
import { W3CTraceContextPropagator } from '@opentelemetry/core'


const Login = ({ isLoading, login }) => {
  const [state, setState] = useState({
    username: '',
    pin: '',
    error: '',
    loading: false,
  });

  const [images, setImages] = useState([]);


  const makeApiCallWithTracer = async (url, tracer, spanName) => {
    const span = tracer.startSpan(spanName);
    const activeContext = context.active();
    const spanWithContext = trace.setSpan(activeContext, span);

    try {
      const headers = {};
      propagation.inject(spanWithContext, headers);
      const response = await axios.get(url, {headers});
      if (response.status != 200) {
        return [response, span]
      } else {
        return [response, span]
      }

    } catch (error) {

      return [error, span]
    }
  }


  useEffect(() => {
    const fetchImages = async () => {
      propagation.setGlobalPropagator(new W3CTraceContextPropagator());
      const tracer = trace.getTracer('api-tracer');
      try {
        setState({
          ...state,
          loading: true,
        })
        const [creditResponse, creditSpan] = await makeApiCallWithTracer(`${ApiUrls.CREDIT_CARD_BANNER_API}`, tracer, "get-credit-card-promo");
        const [insuranceResponse, insuranceSpan] = await makeApiCallWithTracer(`${ApiUrls.LIFE_INSURANCE_BANNER_API}`, tracer, "get-insurance-promo");
        const [carResponse, carSpan] = await makeApiCallWithTracer(`${ApiUrls.CAR_LOAN_BANNER_API}`, tracer, "get-car-loan-promo");
        const [houseLoanResponse, houseSpan] = await makeApiCallWithTracer(`${ApiUrls.HOME_LOAN_BANNER_API}`, tracer, "get-home-loan-promo")

        if (creditResponse.status === 200 && insuranceResponse.status === 200 && carResponse.status === 200 && houseLoanResponse.status === 200) {
          const creditImageUrl = creditResponse.data.details.promo;
          const insuranceImageUrl = insuranceResponse.data.details.promo
          const carImageUrl = carResponse.data.details.promo
          const houseLoanImageUrl = houseLoanResponse.data.details.promo
          setImages([creditImageUrl, insuranceImageUrl, carImageUrl, houseLoanImageUrl]);
          setState({
            ...state,
            loading: false,
          });
          creditSpan.end()
          insuranceSpan.end()
          carSpan.end()
          houseSpan.end()
        } else {
          setState({
            ...state,
            loading: false,
            error: 'Error fetching images. Please try again.'
          });
          creditSpan.setStatus({ code: SpanStatusCode.ERROR, message: creditResponse.message });
          creditSpan.end()

          insuranceSpan.setStatus({ code: SpanStatusCode.ERROR, message: insuranceResponse.message });
          insuranceSpan.end()
          
          carSpan.setStatus({ code: SpanStatusCode.ERROR, message: carResponse.message });
          carSpan.end()

          houseSpan.setStatus({ code: SpanStatusCode.ERROR, message: houseLoanResponse.message });
          houseSpan.end()
        }
      } catch (error) {
        console.log("testing")
        console.log(error)
        console.error('Error fetching images:', error);
        // setImages([Credit, Insurance, Car, HouseLoan]); // Set local images
        setState({
          ...state,
          loading: false,
          error: 'Error fetching images. Please try again.'
        });
      }
    };




    fetchImages();

  }, []);





  // const fallbackImages = [Credit, Insurance, Car, HouseLoan];





  const navigate = useNavigate()
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const { username, pin } = state;
  
    if (!username || !pin) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please provide both pin and username!',
      });
    } else if (username !== "Tangerine88" || pin !== "123456") {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Username or pin is incorrect.',
        confirmButtonText: "Ok"
      });
    } else {
      setState({ ...state, loading: true });
  
      try {
        await login(username, pin);
        setState({ ...state, loading: false });
        navigate('/otp');
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Invalid username or pin. Please try again.',
          confirmButtonText: "Ok"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        setState({ ...state, loading: false });
      }
    }
  };

  const { loading, username, pin, error } = state;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLoginSubmit(e); // Pass the event object here
    }
  };

  return (
    <>
      {loading && <Loading_Scren />}
      <div>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <a className="navbar-brand" href="#" id="navbar-itm-text-logo">
              <img src={Logo} width="150" alt="logo" />
            </a>
            <h6 className="sign-up">
              New User? <span className="sign-two">Sign Up</span>
            </h6>
          </div>
        </nav>
      </div>






      <section className="hero-section">
        <div className="container" id="hero-div">

          <div className="hero-div-next">
            <div className="row">


              <div className="col">
                <div>
                  {!loading && images && <Carousel images={images} interval={10000} />}
                  {/* <img className="hero-img-log" src={Home_Page_Image} alt="home page" /> */}
                </div>
              </div>




              <div className="col">
                <div className="log-content">
                  <h1 className="log-content-h1">Welcome Back!</h1>
                  <p className="log-content-p">Login to continue</p>
                  <div>
                    <input
                      className="input-box-username"
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setState({ ...state, username: e.target.value })}
                      onKeyDown={handleKeyDown}
                    />
                    <br></br>
                    <br></br>

                    <input
                      className="input-box-pin"
                      type="password"
                      value={pin}
                      placeholder="Pin"
                      onChange={(e) => setState({ ...state, pin: e.target.value })}
                      onKeyDown={handleKeyDown}
                    />
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <br></br>
                      <div className="login-btn-nav" onKeyDown={handleKeyDown} onClick={handleLoginSubmit}>
                        Login {"->"}
                      </div>
                    </div>
                    <div className="col-8">
                      <h6 className="forgot-pin">Forgot PIN</h6>
                    </div>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>









      <footer className="public-footer">
        <div className="container">
          <div className="row">
            <div className="col-8" id="foot-content-cop">
              © Apex Global Bank 2022-2024. All rights reserved
            </div>
            <div className="col" id="foot-content-pri">
              Privacy Policy
            </div>
            <div className="col" id="foot-content-ter">
              Terms & Conditions
            </div>
          </div>
        </div>
      </footer>








      {/* Mobile View */}
      <div className="container">
        <section className="mobile-section">
          <center>
            {/* ... your mobile section code ... */}
            <div className="log-content">
              <h1 className="log-content-h1">Welcome Back!</h1>
              <p className="log-content-p">Login to continue</p>
              <div>
                <input
                  className="input-box-username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setState({ ...state, username: e.target.value })}
                  onKeyDown={handleKeyDown}
                />
                <br />
                <br />
                <input
                  className="input-box-pin"
                  type="text"
                  value={pin}
                  placeholder="Pin"
                  onChange={(e) => setState({ ...state, pin: e.target.value })}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="row">
                <div className="col-4">
                  <br></br>
                  <div className="login-btn-nav"onKeyDown={handleKeyDown} onClick={handleLoginSubmit}>
                    Login {"->"}
                  </div>
                </div>
                <div className="col-8">
                  <h6 className="forgot-pin">
                    Forgot PIN
                  </h6>
                </div>
              </div>
              <br />
              <br />
              <Carousel images={images} interval={10000} />
            </div>
          </center>
          <br />

          <br />

        </section>
        <br />
        <br />
        <br />
        <br />
        <footer className="mobile-public-footer">
          <div className="container">
            <div className="row">
              <div className="col-8" id="foot-content-cop">
                © Apex Global Bank 2022-2024. All rights reserved
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    isLoading: state.account.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, pin) => dispatch(AccountActions.login(username, pin)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
